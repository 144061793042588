<template>
  <div class="invoice-header">
    <div class="content">
      <p class="title">开票确认</p>
      <div>
        <div class="form-div">
          <div class="form-table">
            <el-table
              :data="invoiceOrderConfirmList"
              ref="multipleTable"
              style="width: 100%"
              tooltip-effect="dark"
              border
            >
              <el-table-column
                label="申请订单"
                width="auto"
              >
                <template slot-scope="scope">
                  <p>{{ scope.row.orderNum }}笔</p>
                  <p>￥{{ scope.row.totalAmount }}</p>
                </template>
              </el-table-column>
              <el-table-column label="抬头类型" width="auto">
                <template slot-scope="scope">
                  <span>{{ scope.row.merchantType === 'ENTERPRISE' ? '企业单位' : '个人/非企业单位' }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="发票抬头"
                prop="contractInfoName"
                width="150px"
              ></el-table-column>
              <el-table-column
                label="税号\开户行信息"
                prop="taxNo"
                min-width="150px"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.merchantType === 'ENTERPRISE'">
                    <p>税号：{{ scope.row.invoiceTitleVO && scope.row.invoiceTitleVO.taxNo }}</p>
                    <p>开户银行：{{ scope.row.invoiceTitleVO && scope.row.invoiceTitleVO.bankName }}</p>
                    <p>银行账号：{{ scope.row.invoiceTitleVO && scope.row.invoiceTitleVO.bankAccount }}</p>
                    <p>企业电话：{{ scope.row.invoiceTitleVO && scope.row.invoiceTitleVO.mobile }}</p>
                    <p>企业地址：{{ scope.row.invoiceTitleVO && scope.row.invoiceTitleVO.address }}</p>
                  </div>
                  <span v-else>/</span>
                </template>
              </el-table-column>
              <el-table-column label="联系人信息" min-width="150px">
                <template slot-scope="scope">
                  <p>联系姓名：{{ scope.row.invoiceTitleVO && scope.row.invoiceTitleVO.contactName || '--'}}</p>
                  <p>联系人邮箱：{{ scope.row.invoiceTitleVO && scope.row.invoiceTitleVO.email || '--' }}</p>
                  <p>联系人手机号：{{ scope.row.invoiceTitleVO && scope.row.invoiceTitleVO.contactPhone || '--' }}</p>
                  <p>收件地址：{{ scope.row.invoiceTitleVO && scope.row.invoiceTitleVO.contactAddress || '--'}}</p>
                </template>
              </el-table-column>
              <el-table-column label="发票类型" width="350px">
                <template slot-scope="scope">
                  <template v-if="scope.row.invoiceTitleVO">
                    <el-radio-group v-model="scope.row.invoiceTitleVO.invoiceType" :disabled="scope.row.merchantType === 'PERSONAL'">
                      <el-radio label="PLAIN_VAT_INVOICE">增值税普通发票</el-radio>
                      <el-radio label="SPECIAL_VAT_INVOICE">增值税专用发票</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>--</template>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="form-item">
            <span class="label">开票备注：</span>
            <el-input type="textarea" v-model="description" maxlength="100" show-word-limit :rows="3"></el-input>
          </div>
        </div>
        <div class="sure-div">
          <el-button @click="backPage" size="small">取 消</el-button>
          <el-button :loading="saveLoading" @click="sure" size="small" type="primary" :disabled="isDisabled"
            >确定提交</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddInvoice from '@/components/AddInvoice';
import AreaCascader from '@/components/AreaCascader';
import { createInBatch, getInvoiceOrderConfirmList } from '@/api/invoice';
export default {
  name: 'invoice-have-header',
  components: {
    AddInvoice,
    AreaCascader,
  },
  data() {
    return {
      saveLoading: false,
      invoiceOrderConfirmList: [],
      description: '',
      orderIds: [],
      izAllInvoice: '0',
      orderBrandType: '',
      resultId: '',
      isDisabled: false,
    };
  },
  created() {
    this.getMyDefault();
  },
  methods: {
    // 确认开票订单
    async getInvoiceOrderConfirmList(resultId) {
      try {
        const { data } = await getInvoiceOrderConfirmList({ resultId: resultId });
        this.invoiceOrderConfirmList = data;
      } catch {
        this.isDisabled = true;
      }
    },
    getMyDefault() {
      this.orderBrandType = this.$route.query.orderBrandType;
      this.resultId = this.$route.query.resultId;
      this.orderIds = JSON.parse(sessionStorage.orderIds);
      this.izAllInvoice = sessionStorage.izAllInvoice;
      this.getInvoiceOrderConfirmList(this.resultId);
    },
    sure() {
      const { izAllInvoice, resultId, orderBrandType, description, invoiceOrderConfirmList } = this;
      const contractInfoCmdList = invoiceOrderConfirmList.map(item => ({
        distributorContractInfoId: item.distributorContractInfoId,
        invoiceTitleId: item?.invoiceTitleVO?.id || '',
        invoiceType: item?.invoiceTitleVO?.invoiceType || '',
      }));
      this.saveLoading = true;
      createInBatch({
        resultId,
        description,
        izAllInvoice,
        orderBrandType,
        izContractInfo: '1',
        contractInfoCmdList,
      }).then(res => {
        if (res.code === '0') {
          this.$message.success('操作成功');
          this.$router.replace({ path: '/syoung/invoice/invoice-record' });
        }
      }).finally(() => {
        this.saveLoading = false;
      });
    },
    backPage() {
      this.$router.replace('/syoung/invoice/invoice-order');
    },
  },
};
</script>
<style lang="scss" scoped>
.invoice-header {
  .content {
    background: #fff;
    .title {
      margin-top: 15px;
      font-size: 14px;
    }
    .form-div {
      margin-top: 20px;
      .form-table {
        margin-bottom: 40px;
      }
      .form-item {
        display: flex;
        width: 800px;
        margin-bottom: 40px;
        .label {
          display: inline-block;
          width: 75px;
        }
      }
    }
    .sure-div {
      width: 50%;
      text-align: center;
    }
  }
}
</style>